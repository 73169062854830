<template>
  <div>
    <h3> {{ course.name }}</h3>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "elementCourseNameEditor",
  computed : {
    ...mapState('avalonPage',['course'])
  }
}
</script>

<style scoped>

</style>